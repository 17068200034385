import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Radio } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { addAnswer, closeSurvey } from '../../../actions'
import TextField from '@material-ui/core/TextField';

const CustomRadio = withStyles({
    root: {
        color: '#94c948',
        '&$checked': {
            color: '#94c948'
        }
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

const CustomTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#94c948',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#94c948',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'black',
            },
            '&:hover fieldset': {
                borderColor: '#94c948',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#94c948',
            },
        },
    },
})(TextField);

export function SelectQuestion(props) {
    const dispatch = useDispatch();
    const [submitted, setSubmitted] = useState(false);
    const [selectedValue, setSelectedValue] = useState(' ');
    const [freeTextValue, setFreeTextValue] = useState('');

    function submitAnswer() {
        setSubmitted(true);
        dispatch(addAnswer({
            answerType: props.questionData.answerType,
            key: props.questionData.questionKey,
            answer: selectedValue,
            question: props.questionData.paragraphText
        }));

        if (props.questionData.lastQuestion === true) {
            dispatch(closeSurvey());
        }
    }
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };
    function handleFreeTextChange(value) {
        setFreeTextValue(value);
        setSelectedValue(value);
    }
    return (
        <div>
            <p style={{ paddingBottom: '1rem' }}>{props.questionData.paragraphText}</p>
            <div style={{ display: 'flex' }}>
                <div style={{ margin: 'auto' }}>
                    {props.questionData.options.map((option) => {
                        return (
                            <div key={option}>
                                <CustomRadio
                                    checked={selectedValue === option}
                                    disabled={submitted}
                                    onChange={handleChange}
                                    value={option}
                                    id={option}
                                />
                                <label htmlFor={option} name={option + 'lbl'} key={option + 'lbl'}>{option.toUpperCase()}</label>
                            </div>
                        )
                    })}
                    {props.questionData.freeTextOptions ?
                        props.questionData.freeTextOptions.map((freeTextOption) => {
                            return <div key={freeTextOption.label} style={{display: 'flex', alignItems:'center'}}>
                                <CustomRadio
                                    checked={selectedValue === freeTextValue}
                                    disabled={submitted}                                    
                                    value={freeTextValue}
                                    id={freeTextOption.label + 'radio'}
                                    onChange={handleChange}
                                />
                                <CustomTextField
                                    style={{ height:'50%', width: '80%', marginTop: '0.5rem', marginLeft: '0.75rem' }}
                                    disabled={submitted}
                                    id={freeTextOption.label + 'input'}
                                    label={freeTextOption.label.toUpperCase()}
                                    placeholder={freeTextOption.placeHolder}
                                    multiline
                                    variant="outlined"
                                    onChange={e => handleFreeTextChange(e.target.value)}
                                />
                            </div>
                        })
                        : ''
                    }
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <Button disabled={selectedValue === '' || selectedValue === ' '} variant="outlined" style={{ marginLeft: 'auto', marginTop: '1rem' }} hidden={submitted} onClick={submitAnswer}>Spara</Button>
            </div>
        </div>
    )
}