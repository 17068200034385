export const KTSResponderQuestion = {
    paragraphText: 'Är du elev eller handledare?',
    answerType: 'string',
    questionKey: 'responder',
    options: ['elev', 'handledare'],
    lastQuestion: false
}

export const KTSOverallQuestion = {
    paragraphText: `Baserat på ditt helhetsintryck, hur nöjd är du med kursen?`,
    answerType: 'number',
    questionKey: 'overallScore',
    maxValue: 'Mycket nöjd',
    minValue: 'Inte alls nöjd',
    lastQuestion: false
}

export const KTSOverallFollowUpQuestion = {
    paragraphText: 'Något du vill tillägga till ditt helhetsbetyg?',
    answerType: 'string',
    questionKey: 'overallFollowUp',
    label: 'Kommentar',
    placeHolder: 'Dela dina tankar',
    lastQuestion: false
}

export const KTSNpsQuestion = {
    paragraphText: `Baserat på din upplevelse, hur troligt är det att du skulle rekommendera kursen till en vän eller familjemedlem?`,
    answerType: 'number',
    questionKey: 'npsScore',
    maxValue: 'Mycket troligt',
    minValue: 'Inte alls troligt',
    lastQuestion: false
}

export const KTSTeacherQuestion = {
    paragraphText: `Hur upplevde du lärarkompetensen och genomförandet av utbildningen?`,
    answerType: 'number',
    questionKey: 'teacherScore',
    maxValue: 'Väldigt bra',
    minValue: 'Inte alls bra',
    lastQuestion: false
}

export const KTSTeacherFollowUpQuestion = {
    paragraphText: 'Något du vill tillägga till hur du upplevde lärarkompetensen och genomförandet av utbildningen?',
    answerType: 'string',
    questionKey: 'teacherFollowUp',
    label: 'Kommentar',
    placeHolder: 'Dela dina tankar',
    lastQuestion: false
}



export const KTSSourceQuestion = {
    paragraphText: 'Hur hittade du kursen?',
    answerType: 'string',
    questionKey: 'source',
    options: ['hemsida', 'skyltfönster'],
    freeTextOptions: [{ label: 'annat', placeHolder: 'Ange', lastQuestion: false }],
    lastQuestion: false
}

export const KTSImprovementQuestion = {
    paragraphText: 'Slutligen. Finns det något som vi kan göra ännu bättre?',
    answerType: 'string',
    questionKey: 'improvement',
    label: 'Vad kan förbättras?',
    placeHolder: 'Kom med förslag!',
    lastQuestion: true
}
