import React, { useState } from 'react'
import { addAnswer, closeSurvey } from '../../../actions'
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';


const CustomSlider = withStyles({
    root: {
        color: '#94c948',
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#f6f8f7',
        border: '2px solid #7D7D7D',
        marginTop: -12,
        marginLeft: -12
    },
})(Slider);

export function SliderQuestion(props) {
    const dispatch = useDispatch();
    const [score, setScore] = useState(5);
    const [submitted, setSubmitted] = useState(false);
    const paragraphText = props.questionData.paragraphText;

    const handleInputChange = (event, newValue) => {
        setScore(newValue);
    };

    function submitAnswer() {
        setScore()
        setSubmitted(true);
        dispatch(addAnswer({
            answerType: props.questionData.answerType,
            key: props.questionData.questionKey,
            answer: score,
            question: props.questionData.paragraphText
        }));

        if (props.questionData.lastQuestion === true) {
            dispatch(closeSurvey());
        }
    }

    return (
        <div>
            <p style={{ paddingBottom: '1rem' }}>{paragraphText}</p>
            <CustomSlider
                disabled={submitted}
                defaultValue={5}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                onChange={handleInputChange}
                step={1}
                marks
                min={0}
                max={10}
            />
            <div style={{ marginTop: '-0.75rem', marginBottom: '3rem' }}>
                <Typography variant="subtitle2" style={{ position: 'absolute', left: '1rem' }}>{props.questionData.minValue}</Typography>
                <Typography variant="subtitle2" style={{ position: 'absolute', right: '1rem' }}>{props.questionData.maxValue}</Typography>
            </div>
            <div style={{ display: 'flex' }}>
                <Button variant="outlined" style={{ marginLeft: 'auto', marginTop:'1rem'  }} hidden={submitted} onClick={submitAnswer}>Spara</Button>
            </div>
        </div>
    )
}