import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Loader.scss'

export function Loader() {
    return (
        <div className="wrapper">
            <div className="overlay">
                <div className="loader-overlay">
                    <FontAwesomeIcon icon='spinner' size='3x' spin></FontAwesomeIcon>
                </div>
            </div>

            <div className="blurred-overlay"></div>

        </div>
    )
}