import React, { useState, useEffect } from 'react';
import { useStore } from 'react-redux';
import logo from '../../assets/kt/logo.png'
import './Survey.scss'
import { SliderQuestion } from './QuestionTypes/SliderQuestion';
import { SelectQuestion } from './QuestionTypes/SelectQuestion';
import { FreeTextQuestion } from './QuestionTypes/FreeTextQuestion';
import {
    KTSNpsQuestion,
    KTSOverallQuestion,
    KTSResponderQuestion,    
    KTSOverallFollowUpQuestion,
    KTSTeacherQuestion,
    KTSTeacherFollowUpQuestion,
    KTSSourceQuestion,
    KTSImprovementQuestion
} from './Questions/KalmarTrafikskolaQuestions';


export function Survey(props) {

    const store = useStore();
    const [responderQuestionAnswered, setResponderQuestionAnswered] = useState(false)
    const [overallQuestionAnswered, setOverallQuestionAnswered] = useState(false)
    const [overallFollowUpQuestionAnswered, setOverallFollowUpQuestionAnswered] = useState(false)
    const [teacherQuestionAnswered, setTeacherQuestionAnswered] = useState(false)
    const [npsQuestionAnswered, setNpsQuestionAnswered] = useState(false)
    const [teacherFollowUpQuestionAnswered, setTeacherFollowUpQuestionAnswered] = useState(false)
    const [sourceQuestionAnswered, setSourceQuestionAnswered] = useState(false)
    //const [improvementQuestionAnswered, setImprovementQuestionAnswered] = useState(false)

    const courseTitle = getDataValue('courseTitle');
    const eventDate = getDataValue('eventDate');
    const header = `Du genomförde ${courseTitle} ${eventDate}`;

    useEffect(() => {
        store.subscribe(() => {
            const state = store.getState();

            var responderAnswer = filterAnswersOnKey(state.answersReducer.answers, 'responder');
            if (responderAnswer)
                setResponderQuestionAnswered(true);

            var overallAnswer = filterAnswersOnKey(state.answersReducer.answers, 'overallScore');
            if (overallAnswer)
                setOverallQuestionAnswered(true);

            var overallFollowUpAnswer = filterAnswersOnKey(state.answersReducer.answers, 'overallFollowUp');
            if (overallFollowUpAnswer)
                setOverallFollowUpQuestionAnswered(true);

            var teacherAnswer = filterAnswersOnKey(state.answersReducer.answers, 'teacherScore');
            if (teacherAnswer)
                setTeacherQuestionAnswered(true);

            var npsAnswer = filterAnswersOnKey(state.answersReducer.answers, 'npsScore');
            if (npsAnswer)
                setNpsQuestionAnswered(true);

            var teacherFollowUpAnswer = filterAnswersOnKey(state.answersReducer.answers, 'teacherFollowUp');
            if (teacherFollowUpAnswer)
                setTeacherFollowUpQuestionAnswered(true);

            var sourceAnswer = filterAnswersOnKey(state.answersReducer.answers, 'source');
            if (sourceAnswer)
                setSourceQuestionAnswered(true);

            // var improvementAnswer = filterAnswersOnKey(state.answersReducer.answers, 'improvement');
            // if (improvementAnswer)
            //     setImprovementQuestionAnswered(true);

        })

    }, [store])

    function getDataValue(key) {
        return props.surveyData.data.filter(x => x.key === key)[0].value;
    }

    function filterAnswersOnKey(answers, key) {
        if (answers) {
            return answers.filter(x => x.key === key)[0];
        }
    }

    //Here we can check what kind of questions to render based on props
    return (
        <div>
            <div className="logo">
                <img alt="Logo" src={logo}></img>
            </div>

            <h3 style={{ paddingBottom: '1.5rem' }}>{header}</h3>
            {responderQuestionAnswered ? '' : <SelectQuestion questionData={KTSResponderQuestion}></SelectQuestion>}
            {responderQuestionAnswered && overallQuestionAnswered === false ? <SliderQuestion questionData={KTSOverallQuestion}></SliderQuestion> : ''}
            {overallQuestionAnswered && overallFollowUpQuestionAnswered === false ? <FreeTextQuestion questionData={KTSOverallFollowUpQuestion}></FreeTextQuestion> : ''}
            {overallFollowUpQuestionAnswered && teacherQuestionAnswered === false ? <SliderQuestion questionData={KTSTeacherQuestion}></SliderQuestion> : ''}
            {teacherQuestionAnswered && teacherFollowUpQuestionAnswered === false ? <FreeTextQuestion questionData={KTSTeacherFollowUpQuestion}></FreeTextQuestion> : ''}
            {teacherFollowUpQuestionAnswered && npsQuestionAnswered === false ? <SliderQuestion questionData={KTSNpsQuestion}></SliderQuestion> : ''}            
            {npsQuestionAnswered && sourceQuestionAnswered === false ? <SelectQuestion questionData={KTSSourceQuestion}></SelectQuestion> : ''}
            {sourceQuestionAnswered ? <FreeTextQuestion questionData={KTSImprovementQuestion}></FreeTextQuestion> : ''}
        </div>

    )
}