import React from 'react';
import './App.scss';
import { SurveyContainer } from './containers/SurveyContainer'


import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

export class App extends React.Component {
    render() {
        return (
            <Router>
                <div className="container">
                    <div className="col">
                        <Switch>
                            <Route path="/:surveyId">
                                <SurveyContainer></SurveyContainer>
                            </Route>
                        </Switch>
                    </div>
                </ div >
            </Router >
        );
    }
}