import answersReducer from './answers'
import answeredQuestionsReducer from './answeredQuestions'
import closeSurveyReducer from './closeSurvey'
import { combineReducers } from 'redux'
const allReducers = combineReducers({
    answersReducer,
    answeredQuestionsReducer,
    closeSurveyReducer,
})

export default allReducers;