import { useState, useEffect } from "react";

function GetFromUrl(url) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {

        async function fetchFromUrl() {
            const response = await fetch(url);
            const json = await response.json();
            setData(json);
            setLoading(false);
        }

        fetchFromUrl();
    }, [url]);

    return { data, loading };
}

function PostToUrl(url, content) {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(content),
        headers: headers
    };

    return fetch(url, requestOptions)
        .then(response => response.json())
        .then((data) => {
        }).catch((err) => {
            console.error(err);
        })
}

export { GetFromUrl, PostToUrl };