import React from 'react'
import logo from '../../assets/kt/logo.png'
import './NotFound.scss'

export function NotFound() {
    return (
        <div>
            <div className="logo">
                <img className="logo" alt="logo of driving school" src={logo}></img>
            </div>
            <h3>Ingen undersökning hittad</h3>
            <p>Försök klicka på din länk igen.</p>

        </div>
    )
}