import React from 'react'
import './Closed.scss'
import logo from '../../assets/kt/logo.png'

export function Closed() {
    return (
        <div>
            <div className="logo">
                <img className="logo" alt="logo of driving school" src={logo}></img>
            </div>
            <h3>Tack för att du deltog!</h3>
        </div>
    )
}