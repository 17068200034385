const initialState = {
    isClosed: false,
}

const closeSurveyReducer = (state, action) => {

    if (typeof state === 'undefined') {
        return initialState
    }
    let isClosed = false;
    switch (action.type) {
        case 'CLOSE_SURVEY':
            isClosed = true;
            break;
        default: isClosed = false;
    }

    return { isClosed: isClosed }
}

export default closeSurveyReducer;