const initialState = {
    answers: []
}

const answersReducer = (state, action) => {

    if (typeof state === 'undefined') {
        return initialState
    }
    let newAnswers = state.answers.slice();
    switch (action.type) {
        case 'ADD_ANSWER':
            newAnswers.push({
                answerType: action.answerType,
                key: action.key,
                answer: action.answer,
                question: action.question
            })
            break;
        case 'REMOVE_ANSWER':
            newAnswers = newAnswers.filter(x => x.key !== action.key)
            break;

            default: ;
    }

    return { answers: newAnswers }
}

export default answersReducer;