import React from 'react';
import ReactDOM from 'react-dom';
import { App } from "./App";
import './index.scss';
import { createStore } from 'redux';
import allReducers from './reducers';
import { Provider } from 'react-redux'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

library.add(faSpinner);

const store = createStore(allReducers);


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App></App>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);


