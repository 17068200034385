import React, { useState } from 'react'
import { addAnswer, closeSurvey } from '../../../actions'
import { useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#94c948',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#94c948',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
      },
      '&:hover fieldset': {
        borderColor: '#94c948',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#94c948',
      },
    },
  },
})(TextField);

export function FreeTextQuestion(props) {
  const [feedback, setFeedback] = useState('')
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();

  function handleChange(value) {
    setFeedback(value);
  }

  function submitFeedback() {
    dispatch(addAnswer({
      answerType: props.questionData.answerType,
      key: props.questionData.questionKey,
      answer: feedback,
      question: props.questionData.paragraphText
    }));

    if (props.questionData.lastQuestion === true) {
      dispatch(closeSurvey());
    }
    setSubmitted(true);
  }

  return (
    <div>
      <p style={{ paddingBottom: '1rem' }}>{props.questionData.paragraphText}</p>
      <CustomTextField
        style={{ width: '100%', marginTop: '1rem' }}
        disabled={submitted}
        id="standard-textarea"
        label={props.questionData.label}
        placeholder={props.questionData.placeHolder}
        multiline
        variant="outlined"
        onChange={e => handleChange(e.target.value)}
      />
      <div>
        <div style={{ display: 'flex' }}>
          <Button style={{ marginLeft: 'auto', marginBottom:'0.5rem',  marginTop:'1rem'}} variant="outlined" disabled={feedback === ''} onClick={submitFeedback}>Spara</Button>
        </div>
        <div style={{ display: 'flex' }}>
          <Button style={{ marginLeft: 'auto' }} variant="outlined" color="secondary" onClick={submitFeedback}>Hoppa över</Button>
        </div>
      </div>
    </div>
  )
}