import React, { useState, useEffect } from 'react';
import { useStore } from 'react-redux';
import { useParams } from 'react-router-dom'
import { Survey } from '../components/Survey/Survey'
import { Loader } from '../components/Utility/Loader'
import { PostToUrl } from '../components/Utility/fetch'
import { Closed } from '../components/Survey/Closed'
import './SurveyContainer.scss'
import { NotFound } from '../components/Utility/NotFound';



export function SurveyContainer() {
    const params = useParams();
    const store = useStore();
    //const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [closed, setClosed] = useState(false);

    useEffect(() => {

        function closeSurvey() {
            setLoading(true);
            console.log('Setting survey to closed')
            let closeUrl = 'https://adiago-feedbackcollector.azurewebsites.net/Public/v1/surveys/' + params.surveyId + '/close';
    
            PostToUrl(closeUrl, '').finally(() => {
                setLoading(false);
                setClosed(true);
            })
    
        }
        
        function submitAnswer(answer) {

            let submitUrl = 'https://adiago-feedbackcollector.azurewebsites.net/Public/v1/surveys/' + params.surveyId + '/questions';        
            let content = castAnswerToCorrectFormat(answer);
            PostToUrl(submitUrl, content)
                .finally(() => {
                    console.log('Answer submitted')
                })
    
        }
   


        let url = 'https://adiago-feedbackcollector.azurewebsites.net/Public/v1/surveys/' + params.surveyId

        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then((result) => {
                setData(result);
                setClosed(result.closed);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            })

        store.subscribe(() => {
            const state = store.getState();
            const answers = state.answersReducer.answers
            if (answers) {
                var latestAnswer = answers[answers.length -1]
                submitAnswer(latestAnswer)
            }

            if (state.closeSurveyReducer.isClosed) {
                closeSurvey();
            }
        })

    }, [params, store])

    function castAnswerToCorrectFormat(answer) {
        return {
            type: answer.answerType,
            key: answer.key,
            answer: answer.answer.toString(),
            question: answer.question
        }
    }

    if (loading) 
    return (
        <div className='survey-container'>
            <Loader />
        </div>
    )

    if (closed)
    return (
        <div className='survey-container'>
            <Closed />
        </div>
    )
    
    if(!data)
    return (
        <div className='survey-container'>
            <NotFound />
        </div>
    )

    return (
        <div className='survey-container'>
            <Survey surveyData={data}></Survey>
        </div>
    )
}


