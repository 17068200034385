export const addAnswer = (answer) => {
    return {
        type: 'ADD_ANSWER',
        answerType: answer.answerType,
        key: answer.key,
        answer: answer.answer,
        question: answer.question
    }
}
export const removeAnswer = (answer) => {
    return {
        type: 'REMOVE_ANSWER',
        key: answer.key
    }
}

export const addQuestionAnswered = (answeredQuestion) => {
    return {
        type: 'ADD_QUESTION_ANSWERED',
        answeredQuestion: answeredQuestion.value
    }
}

export const removeQuestionAnswered = (answeredQuestion) => {
    return {
        type: 'REMOVE_QUESTION_ANSWERED',
        answeredQuestion: answeredQuestion.value
    }
}

export const closeSurvey = () => {
    return {
        type: 'CLOSE_SURVEY'
    }
}