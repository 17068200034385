const initialState = {
    answeredQuestions: []
}

const answeredQuestionsReducer = (state, action) => {

    if (typeof state === 'undefined') {
        return initialState
    }
    let newAnsweredQuestions = state.answeredQuestions.slice();
    switch (action.type) {
        case 'ADD_QUESTION_ANSWERED':
            newAnsweredQuestions.push({
                answeredQuestion: action.answeredQuestion
            })
            break;
        case 'REMOVE_QUESTION_ANSWERED':
            newAnsweredQuestions = newAnsweredQuestions.filter(x => x.key !== action.answeredQuestion)
            break;

            default : ;
    }

    return { answeredQuestions: newAnsweredQuestions }
}

export default answeredQuestionsReducer;